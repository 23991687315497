<template>
  <div>
    <iframe
      :src="slidoLink"
      ref="chat"
      width="100%"
      height="100%"
      frameBorder="0"
      class="chat align-top"
      title="Slido"
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "Slido",
  props: ['currentUser', 'settings', 'code'],
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
    slidoLink() {
      let link = "";
      let code = this.code;
      let language = this.locale.replace(/_/g, "-");
      let username = this.currentUser.first_name;
      let email = this.currentUser.email;
      let company = this.settings.name;
      link =
        "https://app.sli.do/event/" +
        code +
        "/live/questions?lang=" +
        language +
        "&user_name=" +
        username +
        "&user_email=" +
        email +
        "&user_company=" +
        company;
      console.log(link)
      return link;
    },
  },
};
</script>
<style lang="scss">
.chat {
  height: 100%;
  min-height: 536.364px;
  min-width: 265.455px;
}
</style>